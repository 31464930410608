import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import {
    PieChart,
    Pie,
    Cell,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import "./AdminPage.css";
import axios from "axios";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SentimentComponent from "./SentimentComponent";
import Select from "react-dropdown-select";

const AdminPage = () => {

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + (radius + 120) * Math.cos(-midAngle * RADIAN);
        const y = cy + (radius + 120) * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${name}: ${(percent * 100).toFixed(0)}%, ${value}`}
            </text>
        );
    };

    const SCHOOL_COLORS = ['#CC0033', '#022851', '#003831', '#FEE123', '#5E6A71'];
    // Goes in order: Arizona, Davis, Cal Poly, Oregon, Washington
    // Arizona: [#CC0033 cardinal] #003366 navy
    // Davis: [#022851 aggie blue] #FFBF00 aggie gold
    // Cal Poly: [#003831 green] #FFE395 gold 
    // Oregon: #154733 green [#FEE123 yellow] 
    // Washington: #981E32 crimson [#5E6A71 gray] 

    const [data, setData] = useState({});
    const [chartType, setChartType] = useState("");

    const [feelingData, setFeelingData] = useState([]);
    const [overallData, setOverallData] = useState([]);
    const [influenceData, setInfluenceData] = useState([]);
    const [validData, setValidData] = useState([]);
    const [opinionData, setOpinionData] = useState([]);
    const [newSessionName, setNewSessionName] = useState("");
    const [sessionNames, setSessionNames] = useState([]);
    const [sessionCode, setSessionCode] = useState("");

    const backendURL = "https://wallflowerbackend.elcexercises.org"; 

    useEffect(() => {
        const fetchSessionNames = async (url) => {
            try {
            const response = await axios.get(url); 
            const jsonData = response.data;
              // Extract session names from the response data
            if (jsonData && typeof jsonData === "object") {
                const sessions = Object.entries(jsonData).map(([key, value]) => ({
                    id: key,
                    value: value,
                }));
                setSessionNames(sessions);
            }
            } catch (error) {
              console.error('Error fetching session names:', error);
            }
          };
        fetchSessionNames(backendURL + '/api/getsessionnames');

        const fetchData = async (type, url) => {
            try {
                const response = await axios.get(url);
                const jsonData = response.data;
                setData(jsonData);
                // setChartType(type);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        const fetchSentimentData = async (type, url, setData) => {
            try {
                const response = await axios.get(url);
                const jsonData = response.data;
                if (jsonData && typeof jsonData === "object") {
                    const sentimentArray = Object.entries(jsonData).map(([key, value]) => ({
                        sentiment: key,
                        value: value,
                    }));
                    setData(sentimentArray);
                } else {
                    setData([]);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }

        };

        switch (chartType) {
            case "Video":
                fetchData("Video", backendURL + "/api/getvideochoices?sessioncode=" + sessionCode);

                break;
            case "Application":
                fetchData("Application", backendURL + "/api/getapplicationchoices?sessioncode=" + sessionCode);

                break;
            case "Final":
                fetchData("Final", backendURL + "/api/getpreferredchoices?sessioncode=" + sessionCode);

                break;
            case "Sentiment":
                fetchSentimentData("Feeling", backendURL + "/api/getfeeling?sessioncode=" + sessionCode, setFeelingData);
                fetchSentimentData("Overall", backendURL + "/api/getoverall?sessioncode=" + sessionCode, setOverallData);
                fetchSentimentData("Influence", backendURL + "/api/getinfluence?sessioncode=" + sessionCode, setInfluenceData);
                fetchSentimentData("Valid", backendURL + "/api/getvalid?sessioncode=" + sessionCode, setValidData);
                fetchSentimentData("Submit", backendURL + "/api/getopinion?sessioncode=" + sessionCode, setOpinionData);
                break;
            default:
                break;
        }

    }, [chartType, data, feelingData, overallData, influenceData, validData, opinionData]);

    const questions = [
        "How did you feel when your candidate was rejected?",
        "I found the overall hiring process to be:",
        "In your view, which one of the following factors most heavily influenced the company’s decision in not hiring the candidate that you selected?",
        "Did Marco (The Co-Founder) make valid arguments to support the hiring decision?",
        "Did Marco’s hiring decision influence your opinion of the company?",
    ];

    

    const exportDataToExcel = async () => {
        const apiUrls = {
            video: 'https://wallflowerbackend.elcexercises.org/api/getvideochoices?sessioncode=',
            application: 'https://wallflowerbackend.elcexercises.org/api/getapplicationchoices?sessioncode=',
            final: 'https://wallflowerbackend.elcexercises.org/api/getpreferredchoices?sessioncode=',
            feeling: 'https://wallflowerbackend.elcexercises.org/api/getfeeling?sessioncode=',
            overall: 'https://wallflowerbackend.elcexercises.org/api/getoverall?sessioncode=',
            influence: 'https://wallflowerbackend.elcexercises.org/api/getinfluence?sessioncode=',
            valid: 'https://wallflowerbackend.elcexercises.org/api/getvalid?sessioncode=',
            opinion: 'https://wallflowerbackend.elcexercises.org/api/getopinion?sessioncode=',
        };

        const fetchData = async (url) => {
            try {
                const response = await axios.get(url);
                return response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        const data = {
            video: await fetchData(apiUrls.video + sessionCode),
            application: await fetchData(apiUrls.application + sessionCode),
            final: await fetchData(apiUrls.final + sessionCode),
            feeling: await fetchData(apiUrls.feeling + sessionCode),
            overall: await fetchData(apiUrls.overall + sessionCode),
            influence: await fetchData(apiUrls.influence + sessionCode),
            valid: await fetchData(apiUrls.valid + sessionCode),
            opinion: await fetchData(apiUrls.opinion + sessionCode),
        };

        const workbook = XLSX.utils.book_new();

        const keys = Object.keys(data);

        for (const sheetName in data) {
            let sheetData = data[sheetName];

            if (typeof sheetData === "object" && !Array.isArray(sheetData)) {
                const questionRow = { Q: questions[keys.indexOf(sheetName) - 3] };
                sheetData = [questionRow].concat(
                    Object.entries(sheetData).map(([key, value]) => ({
                        sentiment: key,
                        value: value,
                    }))
                );
            }



            const worksheet = XLSX.utils.json_to_sheet(sheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        }

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const buffer = new ArrayBuffer(wbout.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < wbout.length; i++) {
            view[i] = wbout.charCodeAt(i) & 0xFF;
        }
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `wallflower-output-${formattedDate}.xlsx`);
    };

    const renderChart = () => {
        if (!data) {
            return <p>Loading data...</p>;
        }
        var topChoices = [];
        for (var i = 0; i < data.length; i++) {
            topChoices.push({
                name: data[i].name,
                value: data[i].topchoice
            });
        }
        var bottomChoices = [];
        for (var i = 0; i < data.length; i++) {
            bottomChoices.push({
                name: data[i].name,
                value: data[i].bottomchoice
            });
        }
        var finalChoice = []; 
        for (var i = 0; i < data.length; i++) {
            finalChoice.push({
                name: data[i].name,
                value: data[i].topchoice
            });
        }


        // need a switch case to handle the different types of choices
        // Video and Application choice 
        switch (chartType) {
            case "Video":
                return (
                    <div>
                        <div className="ApplicationChoiceTitle">
                            <h3>Top Video Choice</h3>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={topChoices}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={200}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {topChoices.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={SCHOOL_COLORS[index % SCHOOL_COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="ApplicationChoiceTitle">
                            <h3>Bottom Video Choice</h3>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={450} height={450}>
                                <Pie
                                    data={bottomChoices}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={200}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {bottomChoices.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={SCHOOL_COLORS[index % SCHOOL_COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                    
                )
            case "Application":
                return (
                    <div>
                        <div className="ApplicationChoiceTitle">
                            <h3>Top Application Choice</h3>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={400} height={400}>
                            <Pie
                                    data={topChoices}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={200}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {topChoices.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={SCHOOL_COLORS[index % SCHOOL_COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                        <div className="ApplicationChoiceTitle">
                            <h3>Bottom Application Choice</h3>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={450} height={450}>
                                <Pie
                                    data={bottomChoices}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={200}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {bottomChoices.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={SCHOOL_COLORS[index % SCHOOL_COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                );
            // Final choice
            case "Final":
                return (
                    <div>
                        <div className="ApplicationChoiceTitle">
                            <h3>Final Choice</h3>
                        </div>
                        <ResponsiveContainer width="100%" height="100%">
                            <PieChart width={450} height={450}>
                                <Pie
                                    data={finalChoice}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={200}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {finalChoice.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={SCHOOL_COLORS[index % SCHOOL_COLORS.length]} />
                                    ))}
                                </Pie>
                            </PieChart>
                        </ResponsiveContainer>
                    </div>
                );
            case "Sentiment":
                return (
                    <div>
                        <h1>Additional Responses</h1>
                        <h2>Feeling Data</h2>
                        <SentimentComponent
                            key={0}
                            question={questions[0]}
                            sentimentData={feelingData}
                        />
                        <hr></hr>

                        <h2>Overall Data</h2>
                        <SentimentComponent
                            key={1}
                            question={questions[1]}
                            sentimentData={overallData}
                        />
                        <hr></hr>

                        <h2>Influence Data</h2>
                        <SentimentComponent
                            key={2}
                            question={questions[2]}
                            sentimentData={influenceData}
                        />
                        <hr></hr>

                        <h2>Marco's Argument</h2>
                        <SentimentComponent
                            key={3}
                            question={questions[3]}
                            sentimentData={validData}
                        />
                        <hr></hr>
                        <h2>Marco's Hiring Decision</h2>
                        <SentimentComponent
                            key={4}
                            question={questions[4]}
                            sentimentData={opinionData}
                        />
                    </div>
                );
            default:
                return <p>Select a chart</p>;
        };
    };

    const clearData = async () => {
        try {
            const url = backendURL + '/api/deletesession';
            const data = {sessionCode: sessionCode};
            const response = await axios.post(url,data);
        } catch (error) {
            console.error("Error deleting session:", error);
        }
    }

    const addSession = async (session_name) => {
        if(session_name && window.confirm("Do you want to add session?")) {
            const data = {sessionName: session_name};
            var url = backendURL + '/api/addsession';
            const response = await axios.post(url,data);
            window.alert("New session created! Please refresh to select your session.");
        }
        else if (!session_name) {
            window.alert("Session Name cannot be empty!");
        }
    }

    const switchSession = (event) => {
        const selectedIndex = event.target.options.selectedIndex;
        setSessionCode(event.target.options[selectedIndex].getAttribute('data-key'));
    };

    return (
        <div className="admin-page">
            <div>
                <h4>Sessions</h4>
            </div>
            <div className="button-container">
                <select onChange={(e) => switchSession(e)}>
                    <option value="">Select a session</option>
                    {sessionNames.map(session => (
                        <option key={session.id} data-key={session.value.Session_Code} value={session.value.Session_Name}>
                            {session.value.Session_Name} ({session.value.Session_Code})
                        </option>
                    ))}
                </select>
            </div>
            <div className="button-container">    
                <label>
                    New Session Name:
                    <input type="text" className="text-input-css" value={newSessionName} onChange={e => setNewSessionName(e.target.value)} />
                </label>
                <Button onClick={() => addSession(newSessionName)}>Add Session</Button>
            </div>
            <div className="button-container">
                <Button className="btn btn-outline-secondary button-clear-background" onClick={() => setChartType("Application")}>Application/Resume Choice</Button>
                <Button className="btn btn-outline-secondary button-clear-background" onClick={() => setChartType("Video")}>Video Choice</Button>
                <Button className="btn btn-outline-secondary button-clear-background" onClick={() => setChartType("Final")}>Final Choice</Button>
                <Button className="btn btn-outline-secondary button-clear-background" onClick={() => setChartType("Sentiment")}>Sentiment Data</Button>

                <Button className="btn btn-outline-success button-clear-background" onClick={exportDataToExcel}>Save Data</Button>

                <Button className="btn btn-outline-danger button-clear-background" onClick={() => clearData()} > Clear </Button>

            </div>
            <div className="chart-container">{renderChart()}</div>
        </div>
    );
};

export default AdminPage;
