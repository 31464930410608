import React from "react";

const Menu = (params) =>{
    return (
        <footer className="Footer-in" >
            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;© 2023 Wallflower. All rights reserved
            <br></br>
        </footer>
    );
}

export default Menu;