import pic from "./intro-picture.png";
import React,  { useState } from "react";
import './Intro.css';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import Menu from "./Menu";
import Bottom from "./Bottom";
import axios from 'axios';


function Intro() {
  const [sessionCode, setSessionCode] = useState("");
  let navigate = useNavigate(); 
  const getSession = async (sessionID) => {
    // TODO: need backend API for adding new session IDs
    // url = 'https://wallflowerbackend.elcexercises.org/api/getsession'
    const url = `https://wallflowerbackend.elcexercises.org/api/getsession?sessionCode=${sessionID}`
    try {
      const response = await axios.get(url);
      const jsonData = response.data;
      const id = jsonData[0].ID;
      console.log(id);
      if (response) { // Check if call to get backend data gets anything
      // navigate("/resumes");
      navigate(`/resumes?sessionID=${id}`);
      }
    } catch (error) {
      alert("Session ID does not exist. Please enter a new Session ID.");
      console.log("Session ID does not exist. Unable to login to session.");
      console.log(error)
    }
    
    // if (jsonData && typeof jsonData === "object") {
    //   const sessions = Object.entries(jsonData).map(([key, value]) => ({
    //       id: key,
    //       value: value,
    //   }));
    //   setSessionNames(sessions);
  // }
    // const response = sessionCode !== ""; // "await axios.get(url)";
}

  return (
    <div className="App-in" style={{ textAllign: 'right' }}>
      <header className="Top-of-page-header-in">
        <Menu />
      </header>

      <div class="box background-tint" >
        <header className="App-header-in" >
          <br></br><br></br><br></br>
          <img src={pic} alt="flowers on wall" className="img-in"></img>
          <p style={{ color: `blue`, fontSize: '15px', margin: '0px 50px 0px 150px' }}>
            WALLFLOWER <br></br>
          </p>
          <p style={{ fontSize: '30px', margin: '30px 50px 10px 150px', fontWeight: 'bold' }}>
            Welcome to Wallflower <br></br>
          </p>
          <p style={{ fontSize: '15px', margin: '0px 20px 100px 150px' }}>
            It’s time to meet Wallflower’s co-founders, Marco Esperanza and Cassandra Blackwell. They will be giving you a brief history of Wallflower along with more about the position they’re looking to fill.
            <br></br><br></br>
            Make sure you have a piece of scratch paper handy to take notes as you proceed through the simulation.
            <br></br><br></br>
            Please scroll down below to meet the Co-Founders.
            <br></br><br></br><br></br><hr></hr>
          </p>
        </header>
      </div>

      <header>
        <br></br><br></br><br></br>
        <p style={{ fontWeight: 'bold', fontSize: '30px' }}>
          Wallflower
        </p>
        <p style={{ color: 'gray' }}>
          Please watch the video below and take notes as they review the company <br></br>
          and the position they're looking to fill.<br></br>
          <br></br><br></br>

          <iframe title="main-video" className="youtube" src="https://www.youtube.com/embed/q1NOxhR-rzo?rel=0"> </iframe>
          <br></br>
          <br></br>
          <br></br>
          <input className="session-code-box" placeholder={"Session code..."} type="text" value={sessionCode} onChange={e => setSessionCode(e.target.value)} />
          <Button onClick={() => getSession(sessionCode)}>JOIN SESSION</Button>
          <br></br>
          <br></br>
        </p>
      </header>

      <footer className="Footer-in" >
        <Bottom />
      </footer>
    </div>
  );
}

export default Intro;

