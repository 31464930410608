// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("candidate-placeholder.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#school {\n    padding-bottom: 0.5em;\n}\n#video {\n    padding-top: 5em;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n#button {\n    padding: 2em;\n}", "",{"version":3,"sources":["webpack://./src/PostInterviewCandidate.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,yDAAmD;IACnD,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,YAAY;AAChB","sourcesContent":["#school {\n    padding-bottom: 0.5em;\n}\n#video {\n    padding-top: 5em;\n    background-image: url(\"candidate-placeholder.jpeg\");\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n#button {\n    padding: 2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
